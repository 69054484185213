import React from 'react'

import { StaticQuery, graphql } from 'gatsby'

import { Frame } from '../frame/frame'
import { TeamMember } from '../team-member/team-member'
import styles from './team.module.scss'
import { replaceMarkdown } from '../../utils/replaceMarkdown'

export const Team = () => (
  <StaticQuery
    query={graphql`
      {
        graphCMSData {
          teamMembers(where: { status: PUBLISHED }) {
            id
            fullName
            title
            description
            photo {
              url
            }
            socialMedia {
              type
              link
            }
          }
          sectionHeadings {
            type
            text
          }
        }
      }
    `}
    render={data => (
      <Frame
        id="team"
        bgClassName={styles.bg}
        title="Our Team"
        description={
          <>
            {data.graphCMSData.sectionHeadings
              .filter(heading => heading.type === 'Team')
              .map(heading => (
                <p
                  dangerouslySetInnerHTML={{
                    __html: replaceMarkdown(heading.text),
                  }}
                />
              ))}
          </>
        }
      >
        <ul className={styles.teamMembers}>
          {data.graphCMSData.teamMembers.map(member => (
            <TeamMember
              id={`team-member--${member.id}`}
              member={member}
              key={member.id}
            />
          ))}
        </ul>
      </Frame>
    )}
  />
)
