import React from 'react'

import { Layout } from '../components/layout/layout'
import { IntroLimited } from '../components/intro-limited/intro-limited'
import { Team } from '../components/team/team'
import { Footer } from '../components/footer/footer'

import '../styles/legal-notes.scss'

const PrivacyPolicyPage = () => (
  <Layout>
    <div id="top">
      <IntroLimited />

      <Team />

      <Footer />
    </div>
  </Layout>
)

export default PrivacyPolicyPage
