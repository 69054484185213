import React from 'react'

import styles from './team-member-bio.module.scss'
import { replaceMarkdown } from '../../utils/replaceMarkdown'

export const TeamMemberBio = ({ bio, id }) => (
  <div
    className={styles.bio}
    id={id}
    dangerouslySetInnerHTML={{ __html: replaceMarkdown(bio) }}
  />
)
