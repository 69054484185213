import React from 'react'
import { SocialIcon } from 'react-social-icons'

import styles from './team-member.module.scss'

import { TeamMemberBio } from '../team-member-bio/team-member-bio'

export const TeamMember = ({ member, id }) => (
  <li className={styles.teamMember} id={id}>
    <img
      src={member.photo.url}
      alt={member.fullName}
      className={styles.avatar}
    />
    <h3 className={styles.memberName}>
      <a href={`#${id}`}>{member.fullName}</a>
    </h3>
    <p className={styles.positionName}>{member.title}</p>
    <TeamMemberBio
      id={`team-member-bio--${member.id}`}
      bio={member.description}
      key={member.id}
    />
    <div className={styles.socialIcons}>
      {member.socialMedia.map((social, index) => (
        <SocialIcon key={index}
          color="black"
          style={{
            height: '2rem',
            width: '2rem',
            cursor: 'pointer',
            marginRight: '0.5rem',
          }}
          url={`${social.link}`}
        />
      ))}
    </div>
  </li>
)
